import Vue from 'vue'

import vuetify from '@/plugins/vuetify'

import App from './App.vue'

Vue.config.productionTip = false

Vue.filter('price', price => {
  price = price.toString().padStart(3, '0')

  const first = price.slice(0, -2)
  const last = price.slice(-2)

  if (last !== '00') {
    price = first + ',' + last
  } else {
    price = first
  }

  return price + ' kr'
})
;(async () => {
  new Vue({
    vuetify,
    data: {},
    render: h => h(App),
  }).$mount('#app')
})()
