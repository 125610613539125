// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'

import 'vuetify/dist/vuetify.min.css'
import sv from 'vuetify/es5/locale/sv'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#fd8b06',
      },
    },
  },
  lang: {
    locales: [sv],
    current: 'sv',
  },
}

export default new Vuetify(opts)
