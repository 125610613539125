module.exports = [
  {
    value: '011',
    text: 'Norrköping (011)',
  },
  {
    value: '0120',
    text: 'Åtvidaberg (0120)',
  },
  {
    value: '0121',
    text: 'Söderköping (0121)',
  },
  {
    value: '0122',
    text: 'Finspång (0122)',
  },
  {
    value: '0123',
    text: 'Valdemarsvik (0123)',
  },
  {
    value: '0125',
    text: 'Vikbolandet (0125)',
  },
  {
    value: '013',
    text: 'Linköping (013)',
  },
  {
    value: '0140',
    text: 'Tranås (0140)',
  },
  {
    value: '0141',
    text: 'Motala (0141)',
  },
  {
    value: '0142',
    text: 'Mjölby-Skänninge-Boxholm (0142)',
  },
  {
    value: '0143',
    text: 'Vadstena (0143)',
  },
  {
    value: '0144',
    text: 'Ödeshög (0144)',
  },
  {
    value: '0150',
    text: 'Katrineholm (0150)',
  },
  {
    value: '0151',
    text: 'Vingåker (0151)',
  },
  {
    value: '0152',
    text: 'Strängnäs (0152)',
  },
  {
    value: '0155',
    text: 'Nyköping-Oxelösund (0155)',
  },
  {
    value: '0156',
    text: 'Trosa-Vagnhärad (0156)',
  },
  {
    value: '0157',
    text: 'Flen-Malmköping (0157)',
  },
  {
    value: '0158',
    text: 'Gnesta och Mölnbo-Vårdinge (0158)',
  },
  {
    value: '0159',
    text: 'Mariefred (0159)',
  },
  {
    value: '016',
    text: 'Eskilstuna-Torshälla (016)',
  },
  {
    value: '0171',
    text: 'Enköping (0171)',
  },
  {
    value: '0173',
    text: 'Öregrund-Östhammar (0173)',
  },
  {
    value: '0174',
    text: 'Alunda-Knutby (0174)',
  },
  {
    value: '0175',
    text: 'Hallstavik-Rimbo (0175)',
  },
  {
    value: '0176',
    text: 'Norrtälje (0176)',
  },
  {
    value: '018',
    text: 'Uppsala (018)',
  },
  {
    value: '019',
    text: 'Örebro-Kumla (019)',
  },
  {
    value: '021',
    text: 'Västerås (021)',
  },
  {
    value: '0220',
    text: 'Hallstahammar-Surahammar (0220)',
  },
  {
    value: '0221',
    text: 'Köping (0221)',
  },
  {
    value: '0222',
    text: 'Skinnskatteberg (0222)',
  },
  {
    value: '0223',
    text: 'Fagersta-Norberg (0223)',
  },
  {
    value: '0224',
    text: 'Sala-Heby (0224)',
  },
  {
    value: '0225',
    text: 'Hedemora-Säter (0225)',
  },
  {
    value: '0226',
    text: 'Avesta-Krylbo (0226)',
  },
  {
    value: '0227',
    text: 'Kungsör (0227)',
  },
  {
    value: '023',
    text: 'Falun (023)',
  },
  {
    value: '0240',
    text: 'Ludvika-Smedjebacken (0240)',
  },
  {
    value: '0241',
    text: 'Gagnef-Floda (0241)',
  },
  {
    value: '0243',
    text: 'Borlänge (0243)',
  },
  {
    value: '0246',
    text: 'Svärdsjö-Enviken (0246)',
  },
  {
    value: '0247',
    text: 'Leksand-Insjön (0247)',
  },
  {
    value: '0248',
    text: 'Rättvik (0248)',
  },
  {
    value: '0250',
    text: 'Mora-Orsa (0250)',
  },
  {
    value: '0251',
    text: 'Älvdalen (0251)',
  },
  {
    value: '0253',
    text: 'Idre-Särna (0253)',
  },
  {
    value: '0258',
    text: 'Furudal (0258)',
  },
  {
    value: '026',
    text: 'Gävle-Sandviken (026)',
  },
  {
    value: '0270',
    text: 'Söderhamn (0270)',
  },
  {
    value: '0271',
    text: 'Alfta-Edsbyn (0271)',
  },
  {
    value: '0278',
    text: 'Bollnäs (0278)',
  },
  {
    value: '0280',
    text: 'Malung (0280)',
  },
  {
    value: '0281',
    text: 'Vansbro (0281)',
  },
  {
    value: '0290',
    text: 'Hofors-Storvik (0290)',
  },
  {
    value: '0291',
    text: 'Hedesunda-Österfärnebo (0291)',
  },
  {
    value: '0292',
    text: 'Tärnsjö-Östervåla (0292)',
  },
  {
    value: '0293',
    text: 'Tierp-Söderfors (0293)',
  },
  {
    value: '0294',
    text: 'Karlholmsbruk-Skärplinge (0294)',
  },
  {
    value: '0295',
    text: 'Örbyhus-Dannemora (0295)',
  },
  {
    value: '0297',
    text: 'Ockelbo-Hamrånge (0297)',
  },
  {
    value: '0300',
    text: 'Kungsbacka (0300)',
  },
  {
    value: '0301',
    text: 'Hindås (0301)',
  },
  {
    value: '0302',
    text: 'Lerum (0302)',
  },
  {
    value: '0303',
    text: 'Kungälv (0303)',
  },
  {
    value: '0304',
    text: 'Orust-Tjörn (0304)',
  },
  {
    value: '031',
    text: 'Göteborg-Mölndal-Mölnlycke-Partille (031)',
  },
  {
    value: '0320',
    text: 'Marks kommun (Kinna, Örby m.fl.) (0320)',
  },
  {
    value: '0321',
    text: 'Ulricehamn (0321)',
  },
  {
    value: '0322',
    text: 'Alingsås-Vårgårda (0322)',
  },
  {
    value: '0325',
    text: 'Svenljunga-Tranemo (0325)',
  },
  {
    value: '033',
    text: 'Borås (033)',
  },
  {
    value: '0340',
    text: 'Varberg (0340)',
  },
  {
    value: '0345',
    text: 'Hyltebruk-Torup (0345)',
  },
  {
    value: '0346',
    text: 'Falkenberg (0346)',
  },
  {
    value: '035',
    text: 'Halmstad (035)',
  },
  {
    value: '036',
    text: 'Jönköping-Huskvarna (036)',
  },
  {
    value: '0370',
    text: 'Värnamo (0370)',
  },
  {
    value: '0371',
    text: 'Gislaved-Anderstorp (0371)',
  },
  {
    value: '0372',
    text: 'Ljungby (0372)',
  },
  {
    value: '0380',
    text: 'Nässjö (0380)',
  },
  {
    value: '0381',
    text: 'Eksjö (0381)',
  },
  {
    value: '0382',
    text: 'Sävsjö (0382)',
  },
  {
    value: '0383',
    text: 'Vetlanda (0383)',
  },
  {
    value: '0390',
    text: 'Gränna (0390)',
  },
  {
    value: '0392',
    text: 'Mullsjö (0392)',
  },
  {
    value: '0393',
    text: 'Vaggeryd (0393)',
  },
  {
    value: '040',
    text: 'Malmö (040)',
  },
  {
    value: '0410',
    text: 'Trelleborg (0410)',
  },
  {
    value: '0411',
    text: 'Ystad (0411)',
  },
  {
    value: '0413',
    text: 'Eslöv-Höör (0413)',
  },
  {
    value: '0414',
    text: 'Simrishamn (0414)',
  },
  {
    value: '0415',
    text: 'Hörby (0415)',
  },
  {
    value: '0416',
    text: 'Sjöbo (0416)',
  },
  {
    value: '0417',
    text: 'Tomelilla (0417)',
  },
  {
    value: '0418',
    text: 'Landskrona-Svalöv (0418)',
  },
  {
    value: '042',
    text: 'Helsingborg-Höganäs (042)',
  },
  {
    value: '0430',
    text: 'Laholm (0430)',
  },
  {
    value: '0431',
    text: 'Ängelholm-Båstad (0431)',
  },
  {
    value: '0433',
    text: 'Markaryd-Strömsnäsbruk (0433)',
  },
  {
    value: '0435',
    text: 'Klippan-Perstorp (0435)',
  },
  {
    value: '044',
    text: 'Kristianstad (044)',
  },
  {
    value: '0451',
    text: 'Hässleholm (0451)',
  },
  {
    value: '0454',
    text: 'Karlshamn-Olofström (0454)',
  },
  {
    value: '0455',
    text: 'Karlskrona (0455)',
  },
  {
    value: '0456',
    text: 'Sölvesborg-Bromölla (0456)',
  },
  {
    value: '0457',
    text: 'Ronneby (0457)',
  },
  {
    value: '0459',
    text: 'Ryd (0459)',
  },
  {
    value: '046',
    text: 'Lund-Staffanstorp (046)',
  },
  {
    value: '0470',
    text: 'Växjö (0470)',
  },
  {
    value: '0471',
    text: 'Emmaboda (0471)',
  },
  {
    value: '0472',
    text: 'Alvesta-Rydaholm (0472)',
  },
  {
    value: '0474',
    text: 'Åseda-Lenhovda (0474)',
  },
  {
    value: '0476',
    text: 'Älmhult (0476)',
  },
  {
    value: '0477',
    text: 'Tingsryd (0477)',
  },
  {
    value: '0478',
    text: 'Lessebo (0478)',
  },
  {
    value: '0479',
    text: 'Osby (0479)',
  },
  {
    value: '0480',
    text: 'Kalmar (0480)',
  },
  {
    value: '0481',
    text: 'Nybro (0481)',
  },
  {
    value: '0485',
    text: 'Öland (0485)',
  },
  {
    value: '0486',
    text: 'Torsås (0486)',
  },
  {
    value: '0490',
    text: 'Västervik (0490)',
  },
  {
    value: '0491',
    text: 'Oskarshamn-Högsby (0491)',
  },
  {
    value: '0492',
    text: 'Vimmerby (0492)',
  },
  {
    value: '0493',
    text: 'Gamleby (0493)',
  },
  {
    value: '0494',
    text: 'Kisa (0494)',
  },
  {
    value: '0495',
    text: 'Hultsfred-Virserum (0495)',
  },
  {
    value: '0496',
    text: 'Mariannelund (0496)',
  },
  {
    value: '0498',
    text: 'Gotland (0498)',
  },
  {
    value: '0499',
    text: 'Mönsterås (0499)',
  },
  {
    value: '0500',
    text: 'Skövde (0500)',
  },
  {
    value: '0501',
    text: 'Mariestad (0501)',
  },
  {
    value: '0502',
    text: 'Tidaholm (0502)',
  },
  {
    value: '0503',
    text: 'Hjo (0503)',
  },
  {
    value: '0504',
    text: 'Tibro (0504)',
  },
  {
    value: '0505',
    text: 'Karlsborg (0505)',
  },
  {
    value: '0506',
    text: 'Töreboda-Hova (0506)',
  },
  {
    value: '0510',
    text: 'Lidköping (0510)',
  },
  {
    value: '0511',
    text: 'Skara-Götene (0511)',
  },
  {
    value: '0512',
    text: 'Vara-Nossebro (0512)',
  },
  {
    value: '0513',
    text: 'Herrljunga (0513)',
  },
  {
    value: '0514',
    text: 'Grästorp (0514)',
  },
  {
    value: '0515',
    text: 'Falköping (0515)',
  },
  {
    value: '0520',
    text: 'Trollhättan (0520)',
  },
  {
    value: '0521',
    text: 'Vänersborg (0521)',
  },
  {
    value: '0522',
    text: 'Uddevalla (0522)',
  },
  {
    value: '0523',
    text: 'Lysekil (0523)',
  },
  {
    value: '0524',
    text: 'Munkedal (0524)',
  },
  {
    value: '0525',
    text: 'Grebbestad (0525)',
  },
  {
    value: '0526',
    text: 'Strömstad (0526)',
  },
  {
    value: '0528',
    text: 'Färgelanda (0528)',
  },
  {
    value: '0530',
    text: 'Mellerud (0530)',
  },
  {
    value: '0531',
    text: 'Bengtsfors (0531)',
  },
  {
    value: '0532',
    text: 'Åmål (0532)',
  },
  {
    value: '0533',
    text: 'Säffle (0533)',
  },
  {
    value: '0534',
    text: 'Ed (0534)',
  },
  {
    value: '054',
    text: 'Karlstad-Skoghall (054)',
  },
  {
    value: '0550',
    text: 'Kristinehamn (0550)',
  },
  {
    value: '0551',
    text: 'Gullspång (0551)',
  },
  {
    value: '0552',
    text: 'Deje (0552)',
  },
  {
    value: '0553',
    text: 'Molkom (0553)',
  },
  {
    value: '0554',
    text: 'Kil (0554)',
  },
  {
    value: '0555',
    text: 'Grums (0555)',
  },
  {
    value: '0560',
    text: 'Torsby (0560)',
  },
  {
    value: '0563',
    text: 'Hagfors-Munkfors (0563)',
  },
  {
    value: '0564',
    text: 'Sysslebäck (0564)',
  },
  {
    value: '0565',
    text: 'Sunne (0565)',
  },
  {
    value: '0570',
    text: 'Arvika (0570)',
  },
  {
    value: '0571',
    text: 'Charlottenberg-Åmotfors (0571)',
  },
  {
    value: '0573',
    text: 'Årjäng (0573)',
  },
  {
    value: '0580',
    text: 'Kopparberg (0580)',
  },
  {
    value: '0581',
    text: 'Lindesberg (0581)',
  },
  {
    value: '0582',
    text: 'Hallsberg (0582)',
  },
  {
    value: '0583',
    text: 'Askersund (0583)',
  },
  {
    value: '0584',
    text: 'Laxå (0584)',
  },
  {
    value: '0585',
    text: 'Fjugesta-Svartå (0585)',
  },
  {
    value: '0586',
    text: 'Karlskoga (0586)',
  },
  {
    value: '0587',
    text: 'Nora (0587)',
  },
  {
    value: '0589',
    text: 'Arboga (0589)',
  },
  {
    value: '0590',
    text: 'Filipstad (0590)',
  },
  {
    value: '0591',
    text: 'Hällefors-Grythyttan-Fredriksberg (0591)',
  },
  {
    value: '060',
    text: 'Sundsvall-Timrå (060)',
  },
  {
    value: '0611',
    text: 'Härnösand (0611)',
  },
  {
    value: '0612',
    text: 'Kramfors (0612)',
  },
  {
    value: '0613',
    text: 'Ullånger (0613)',
  },
  {
    value: '0620',
    text: 'Sollefteå (0620)',
  },
  {
    value: '0621',
    text: 'Junsele (0621)',
  },
  {
    value: '0622',
    text: 'Näsåker (0622)',
  },
  {
    value: '0623',
    text: 'Ramsele (0623)',
  },
  {
    value: '0624',
    text: 'Backe (0624)',
  },
  {
    value: '063',
    text: 'Östersund (063)',
  },
  {
    value: '0640',
    text: 'Krokom (0640)',
  },
  {
    value: '0642',
    text: 'Lit (0642)',
  },
  {
    value: '0643',
    text: 'Hallen-Oviken (0643)',
  },
  {
    value: '0644',
    text: 'Hammerdal (0644)',
  },
  {
    value: '0645',
    text: 'Föllinge (0645)',
  },
  {
    value: '0647',
    text: 'Åre-Järpen (0647)',
  },
  {
    value: '0650',
    text: 'Hudiksvall (0650)',
  },
  {
    value: '0651',
    text: 'Ljusdal (0651)',
  },
  {
    value: '0652',
    text: 'Bergsjö (0652)',
  },
  {
    value: '0653',
    text: 'Delsbo (0653)',
  },
  {
    value: '0657',
    text: 'Los (0657)',
  },
  {
    value: '0660',
    text: 'Örnsköldsvik (0660)',
  },
  {
    value: '0661',
    text: 'Bredbyn (0661)',
  },
  {
    value: '0662',
    text: 'Björna (0662)',
  },
  {
    value: '0663',
    text: 'Husum (0663)',
  },
  {
    value: '0670',
    text: 'Strömsund (0670)',
  },
  {
    value: '0671',
    text: 'Hoting (0671)',
  },
  {
    value: '0672',
    text: 'Gäddede (0672)',
  },
  {
    value: '0680',
    text: 'Sveg (0680)',
  },
  {
    value: '0682',
    text: 'Rätan (0682)',
  },
  {
    value: '0684',
    text: 'Hede-Funäsdalen (0684)',
  },
  {
    value: '0687',
    text: 'Svenstavik (0687)',
  },
  {
    value: '0690',
    text: 'Ånge (0690)',
  },
  {
    value: '0691',
    text: 'Torpshammar (0691)',
  },
  {
    value: '0692',
    text: 'Liden (0692)',
  },
  {
    value: '0693',
    text: 'Bräcke-Gällö (0693)',
  },
  {
    value: '0695',
    text: 'Stugun (0695)',
  },
  {
    value: '0696',
    text: 'Hammarstrand (0696)',
  },
  {
    value: '08',
    text: 'Stockholm (08)',
  },
  {
    value: '090',
    text: 'Umeå (090)',
  },
  {
    value: '0910',
    text: 'Skellefteå (0910)',
  },
  {
    value: '0911',
    text: 'Piteå (0911)',
  },
  {
    value: '0912',
    text: 'Byske (0912)',
  },
  {
    value: '0913',
    text: 'Lövånger (0913)',
  },
  {
    value: '0914',
    text: 'Burträsk (0914)',
  },
  {
    value: '0915',
    text: 'Bastuträsk (0915)',
  },
  {
    value: '0916',
    text: 'Jörn (0916)',
  },
  {
    value: '0918',
    text: 'Norsjö (0918)',
  },
  {
    value: '0920',
    text: 'Luleå (0920)',
  },
  {
    value: '0921',
    text: 'Boden (0921)',
  },
  {
    value: '0922',
    text: 'Haparanda (0922)',
  },
  {
    value: '0923',
    text: 'Kalix (0923)',
  },
  {
    value: '0924',
    text: 'Råneå (0924)',
  },
  {
    value: '0925',
    text: 'Lakaträsk (0925)',
  },
  {
    value: '0926',
    text: 'Överkalix (0926)',
  },
  {
    value: '0927',
    text: 'Övertorneå (0927)',
  },
  {
    value: '0928',
    text: 'Harads (0928)',
  },
  {
    value: '0929',
    text: 'Älvsbyn (0929)',
  },
  {
    value: '0930',
    text: 'Nordmaling (0930)',
  },
  {
    value: '0932',
    text: 'Bjurholm (0932)',
  },
  {
    value: '0933',
    text: 'Vindeln (0933)',
  },
  {
    value: '0934',
    text: 'Robertsfors (0934)',
  },
  {
    value: '0935',
    text: 'Vännäs (0935)',
  },
  {
    value: '0940',
    text: 'Vilhelmina (0940)',
  },
  {
    value: '0941',
    text: 'Åsele (0941)',
  },
  {
    value: '0942',
    text: 'Dorotea (0942)',
  },
  {
    value: '0943',
    text: 'Fredrika (0943)',
  },
  {
    value: '0950',
    text: 'Lycksele (0950)',
  },
  {
    value: '0951',
    text: 'Storuman (0951)',
  },
  {
    value: '0952',
    text: 'Sorsele (0952)',
  },
  {
    value: '0953',
    text: 'Malå (0953)',
  },
  {
    value: '0954',
    text: 'Tärnaby (0954)',
  },
  {
    value: '0960',
    text: 'Arvidsjaur (0960)',
  },
  {
    value: '0961',
    text: 'Arjeplog (0961)',
  },
  {
    value: '0970',
    text: 'Gällivare (0970)',
  },
  {
    value: '0971',
    text: 'Jokkmokk (0971)',
  },
  {
    value: '0973',
    text: 'Porjus (0973)',
  },
  {
    value: '0975',
    text: 'Hakkas (0975)',
  },
  {
    value: '0976',
    text: 'Vuollerim (0976)',
  },
  {
    value: '0977',
    text: 'Korpilombolo (0977)',
  },
  {
    value: '0978',
    text: 'Pajala (0978)',
  },
  {
    value: '0980',
    text: 'Kiruna (0980)',
  },
  {
    value: '0981',
    text: 'Vittangi (0981)',
  },
]
