<template>
  <v-app v-if="broadband.possibleNets !== null">
    <v-container>
      <div
        v-if="!hideLogo"
        class="text-center mb-3"
      >
        <img
          src="./assets/logotype.png"
          alt="Voictech"
        />
      </div>

      <div>
        <template v-if="customerDetails.type === null">
          <h2>Företag eller privatperson?</h2>
          <p>Voicetechs tjänsteutbud skiljer sig åt beroende på om du tecknar abonnemang som företag eller som privatperson.</p>

          <v-btn @click="customerDetails.type = 'COMPANY'">
            <v-icon class="mr-1"> mdi-domain </v-icon>
            Förening eller företag
          </v-btn>

          <v-btn
            class="ml-3"
            @click="customerDetails.type = 'INDIVIDUAL'"
          >
            <v-icon class="mr-1"> mdi-account </v-icon>
            Privatperson
          </v-btn>
        </template>

        <template v-else-if="orderIsSent">
          <h2>Tack för din beställning!</h2>
          <p>Vi återkopplar till dig när beställningen har hanterats.</p>

          <p>
            Har du några frågor är du välkommen att skriva till
            <a href="mailto:info@voicetech.se">info@voicetech.se</a>
            eller ringa till
            <a href="tel:+46470501314">0470-501314</a>.
          </p>
        </template>

        <template v-else>
          <v-stepper
            v-model="step"
            class="mb-3"
            non-linear
          >
            <v-stepper-header>
              <template v-for="(title, index) in titles">
                <v-stepper-step
                  v-if="title !== null"
                  :key="'step-' + index"
                  :step="index"
                  :complete="isOk(index)"
                  :editable="index !== 3 || okUntil(index - 1)"
                >
                  {{ title }}
                </v-stepper-step>

                <v-divider
                  v-if="title !== null && index < titles.length - 1"
                  :key="'div-' + index"
                />
              </template>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                v-for="(title, index) in titles.filter(x => x !== null)"
                :key="'step-content-' + (index + 1)"
                :step="index + 1"
              >
                <v-card>
                  <v-card-title>
                    <h2>
                      {{ title }}
                    </h2>
                  </v-card-title>

                  <v-card-text v-if="index === 0">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-checkbox
                          v-model="broadband.isActive"
                          label="Jag vill beställa bredband"
                        />
                      </v-col>

                      <v-col
                        v-if="broadband.isActive"
                        cols="12"
                        sm="8"
                      >
                        <v-select
                          v-model="broadband.netChoice"
                          :items="broadband.possibleNets[customerDetails.type]"
                          label="Välj stadsnät"
                          return-object
                        />

                        <template v-if="broadband.netChoice !== null">
                          <h3>Vilken hastighet önskar du på din bredbandsanslutning?</h3>

                          <v-radio-group v-model="broadband.speedChoice">
                            <v-radio
                              v-for="speed in broadband.netChoice.speeds"
                              :key="speed.id"
                              :label="speed.name + ' (' + $options.filters.price(speed.price) + '/mån)'"
                              :value="speed"
                            />
                          </v-radio-group>
                        </template>
                      </v-col>
                    </v-row>

                    <v-divider />

                    <v-row>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-checkbox
                          v-model="telephony.isActive"
                          label="Jag vill beställa telefoni"
                        />
                      </v-col>

                      <v-col
                        v-if="telephony.isActive"
                        cols="12"
                        sm="8"
                        class="pt-8"
                      >
                        <h3>Vilket abonnemang vill du ha?</h3>

                        <v-radio-group v-model="telephony.subscriptionChoice">
                          <v-radio
                            v-for="subscription in telephony.possibleSubscriptions[customerDetails.type]"
                            :key="subscription.id"
                            :label="subscription.name + ' (' + $options.filters.price(subscription.price) + '/mån)'"
                            :value="subscription"
                          />
                        </v-radio-group>

                        <v-select
                          v-model="telephony.numberChoice"
                          :items="telephony.numberAction"
                          label="Vilket telefonnummer vill du ha?"
                        />

                        <template v-if="telephony.numberChoice === 'KEEP'">
                          <v-text-field
                            v-model="telephony.numberKeep.number"
                            label="Telefonnummer att flytta"
                          />

                          <v-text-field
                            v-model="telephony.numberKeep.carrier"
                            label="Nuvarande operatör"
                          />
                        </template>

                        <template v-if="telephony.numberChoice === 'NEW'">
                          <v-autocomplete
                            v-model="telephony.numberNew.prefix"
                            :items="telephony.numberNew.prefixes"
                            label="Vilket riktnummer vill du ha det nya telefonnumret i?"
                            no-data-text="Hittade inget riktnummerområde"
                            return-object
                          />
                        </template>

                        <v-container v-if="telephony.numberChoice !== null">
                          <v-switch
                            v-for="possibleExtra in telephony.possibleExtras"
                            :key="possibleExtra.id"
                            v-model="telephony.extraChoices"
                            :label="possibleExtra.name + ' (' + (possibleExtra.price === null ? 'gratis' : '+' + $options.filters.price(possibleExtra.price)) + (possibleExtra.monthly ? '/mån' : '') + ')'"
                            :value="possibleExtra.id"
                            :class="{
                              'ma-0': true,
                              'pa-0': true,
                              'mb-3': possibleExtra.required,
                            }"
                            multiple
                            :disabled="possibleExtra.required"
                            persistent-hint
                            :hint="possibleExtra.required ? 'För att använda tjänsten måste du ha en telefonibox' : undefined"
                          />
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text v-if="index === 1">
                    <v-form>
                      <v-row dense>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-text-field
                            v-model="customerDetails.name"
                            :label="customerDetails.type === 'INDIVIDUAL' ? 'För- och efternamn' : 'Förenings- eller företagsnamn'"
                            :rules="[customerDetails.rules.required]"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-text-field
                            v-model="customerDetails.identityNumber"
                            :label="customerDetails.type === 'INDIVIDUAL' ? 'Personnummer' : 'Organisationsnummer'"
                            :rules="[customerDetails.rules.required]"
                            @blur="customerDetails.identityNumber = transformIdentityNumber(customerDetails.identityNumber, customerDetails.type)"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-text-field
                            v-model="customerDetails.emailAddress"
                            label="E-postadress"
                            :rules="[customerDetails.rules.required, customerDetails.rules.emailAddress]"
                            validate-on-blur
                            @blur="customerDetails.emailAddress = transformEmailAddress(customerDetails.emailAddress)"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-text-field
                            v-model="customerDetails.phoneNumber"
                            label="Telefonnummer"
                            :rules="[customerDetails.rules.phoneNumber]"
                            @blur="customerDetails.phoneNumber = transformPhoneNumber(customerDetails.phoneNumber)"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-text-field
                            v-model="customerDetails.street"
                            label="Gatuadress"
                            :rules="[customerDetails.rules.required]"
                          />
                        </v-col>

                        <v-col
                          cols="4"
                          sm="2"
                        >
                          <v-text-field
                            v-model="customerDetails.zipCode"
                            label="Postnummer"
                            :rules="[customerDetails.rules.required, customerDetails.rules.zipCode]"
                            @blur="customerDetails.zipCode = transformZipCode(customerDetails.zipCode)"
                          />
                        </v-col>

                        <v-col
                          cols="8"
                          sm="4"
                        >
                          <v-text-field
                            v-model="customerDetails.city"
                            label="Postort"
                            :rules="[customerDetails.rules.required]"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-select
                            v-model="customerDetails.preferredInvoiceDelivery"
                            label="Hur vill du att fakturan skickas?"
                            :items="customerDetails.invoiceDeliveryMethods"
                            hint="Autogiro går att välja när du har fått din första faktura"
                            persistent-hint
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>

                  <v-card-text v-if="index === 2 && okUntil(index)">
                    <v-container>
                      <h2>Bredband</h2>

                      <template v-if="broadband.isActive">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ broadband.netChoice.text }}
                            </v-list-item-title>
                            <v-list-item-subtitle> Stadsnät </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ broadband.speedChoice.name }}
                              ({{ broadband.speedChoice.price | price }}/mån)
                            </v-list-item-title>
                            <v-list-item-subtitle> Hastighet </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <p
                        v-else
                        class="mb-0"
                      >
                        Ingen bredbandstjänst beställd.
                      </p>
                    </v-container>

                    <v-divider />

                    <v-container>
                      <h2>Telefoni</h2>

                      <template v-if="telephony.isActive">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ telephony.subscriptionChoice.name }}
                              ({{ telephony.subscriptionChoice.price | price }}/mån)
                            </v-list-item-title>
                            <v-list-item-subtitle> Abonnemang </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ telephony.numberAction.find(x => x.value === telephony.numberChoice).text }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <template v-if="telephony.numberChoice === 'KEEP'">
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ telephony.numberKeep.number }}
                                hos
                                {{ telephony.numberKeep.carrier }}
                              </v-list-item-title>
                              <v-list-item-subtitle> Telefonnummer att flytta </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <template v-if="telephony.numberChoice === 'NEW'">
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ telephony.numberNew.prefix.text }}
                              </v-list-item-title>
                              <v-list-item-subtitle> Riktnummer för det nya telefonnumret </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <v-list-item
                          v-for="choice in telephony.extraChoices.map(x => telephony.possibleExtras.find(y => y.id === x))"
                          :key="choice.id"
                        >
                          <v-list-item-icon class="mr-3 mb-0 mt-0 align-self-center">
                            <v-icon>
                              {{ choice.icon }}
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>
                              {{ choice.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="choice.price !== null"> +{{ $options.filters.price(choice.price) + (choice.monthly ? '/mån' : '') }} </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <p
                        v-else
                        class="mb-0"
                      >
                        Ingen telefonitjänst beställd.
                      </p>
                    </v-container>

                    <v-divider />

                    <v-container>
                      <h2>Kunduppgifter</h2>

                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ customerDetails.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>Namn</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item v-if="customerDetails.phoneNumber">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ customerDetails.phoneNumber }}
                              </v-list-item-title>
                              <v-list-item-subtitle> Telefonnummer </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item v-if="customerDetails.emailAddress">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ customerDetails.emailAddress }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                E-postadress
                                <template v-if="customerDetails.preferredInvoiceDelivery === 'EMAIL'"> (fakturan skickas hit) </template>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ customerDetails.identityNumber }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ customerDetails.type === 'INDIVIDUAL' ? 'Personnummer' : 'Organisationsnummer' }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ customerDetails.street }}<br />
                                {{ customerDetails.zipCode }}
                                {{ customerDetails.city }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Adress
                                <template v-if="customerDetails.preferredInvoiceDelivery === 'PAPER'"> (fakturan skickas hit, +25 kr/mån) </template>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-divider />

                    <v-container>
                      <v-textarea
                        v-model="customerDetails.comment"
                        outlined
                        class="mt-2"
                        placeholder="Meddelande till Voicetech (valfritt)"
                      />
                    </v-container>

                    <v-divider />

                    <v-container>
                      <v-row no-gutters>
                        <v-col
                          cols="8"
                          md="4"
                        >
                          <v-dialog
                            ref="dateDialog"
                            v-model="activationDateModal"
                            :return-value.sync="activationDate"
                            persistent
                            width="290px"
                          >
                            <template #activator="{ on, attrs }">
                              <v-text-field
                                v-model="activationDate"
                                :label="'När vill du att ' + (broadband.isActive && telephony.isActive ? 'tjänsterna' : 'tjänsten') + ' ska aktiveras?'"
                                readonly
                                v-bind="attrs"
                                class="mr-5"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="activationDate"
                              :min="new Date().toISOString().slice(0, 10)"
                              scrollable
                            >
                              <v-spacer />
                              <v-btn
                                text
                                @click="activationDateModal = false"
                              >
                                Avbryt
                              </v-btn>
                              <v-btn
                                color="primary"
                                @click="saveActivationDate(activationDate)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>

                        <v-col
                          cols="12"
                          md="8"
                        >
                          <v-checkbox
                            v-model="termsOfService"
                            label="Jag har läst igenom och accepterar Voicetechs allmänna villkor"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      v-if="step > 1"
                      @click="toStep(step - 1)"
                    >
                      Tillbaka
                    </v-btn>

                    <v-btn
                      v-if="step < titles.length - 1"
                      color="primary"
                      :disabled="!okUntil(step)"
                      @click="toStep(step + 1)"
                    >
                      Fortsätt
                    </v-btn>

                    <v-btn
                      v-if="step === titles.length - 1"
                      color="primary"
                      large
                      :disabled="!termsOfService || isSendingOrder"
                      :loading="isSendingOrder"
                      @click="submitOrder"
                    >
                      Slutför beställning
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import prefixes from './assets/prefixes.js'
import axios from 'axios'

export default {
  data: () => ({
    hideLogo: false,
    titles: [null, 'Tjänster och tillval', 'Kund- och leveransuppgifter', 'Bekräfta beställning'],
    step: 1,
    orderIsSent: false,
    isSendingOrder: false,
    broadband: {
      isActive: false,
      possibleNets: null,
      netChoice: null,
      speedChoice: null,
    },
    telephony: {
      isActive: false,
      possibleSubscriptions: null,
      subscriptionChoice: null,
      numberAction: [
        {
          value: 'KEEP',
          text: 'Flytta befinligt telefonnummer till Voicetech (+149 kr)',
        },
        {
          value: 'NEW',
          text: 'Beställ ett nytt telefonnummer (gratis)',
        },
      ],
      numberChoice: null,
      numberKeep: {
        number: null,
        carrier: null,
      },
      numberNew: {
        prefixies: [],
        prefix: null,
      },
      possibleExtras: [
        {
          id: 'PHONE_BOX',
          name: 'Telefonibox',
          price: 84900,
          monthly: false,
          required: true,
          icon: 'mdi-router-wireless',
        },
        {
          id: 'PRIORITY',
          name: 'Prioriterad IP-telefoni',
          price: 2000,
          monthly: true,
          icon: 'mdi-home-floor-1',
        },
        {
          id: 'BLOCK_PAYMENT',
          name: 'Blockera betalsamtal',
          price: null,
          icon: 'mdi-cash-usd',
        },
        {
          id: 'BLOCK_FOREIGN',
          name: 'Blockera utlandssamtal',
          price: null,
          icon: 'mdi-earth',
        },
        {
          id: 'BLOCK_CELLPHONES',
          name: 'Blockera samtal till mobiltelefoner',
          price: null,
          icon: 'mdi-cellphone',
        },
        {
          id: 'HIDDEN_NUMBER',
          name: 'Dolt nummer',
          price: null,
          icon: 'mdi-lock',
        },
      ],
      extraChoices: ['PHONE_BOX'],
    },
    customerDetails: {
      invoiceDeliveryMethods: [
        {
          value: 'EMAIL',
          text: 'Via e-post (gratis)',
          explanation: 'Fakturan skickas via e-post (gratis).',
        },
        {
          value: 'PAPER',
          text: 'Via vanlig post (+25 kr/mån)',
          explanation: 'Fakturan skickas via vanlig post (+25 kr/mån).',
        },
      ],
      type: null,
      name: '',
      identityNumber: '',
      emailAddress: '',
      phoneNumber: '',
      street: '',
      zipCode: '',
      city: '',
      preferredInvoiceDelivery: 'EMAIL',
      comment: '',
      rules: {
        required: v => !!v || 'Fyll i detta fält.',
        phoneNumber: v => /^\+?[ \-0-9]+$/.test(v) || 'Ogiltigt telefonnummer.',
        emailAddress: v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || 'Ogiltig e-postadress.'
        },
        zipCode: v => /^[0-9 ]+$/.test(v) || 'Endast siffror.',
      },
    },
    termsOfService: false,
    activationDateModal: false,
    activationDate: null,
  }),

  watch: {
    'customerDetails.type': function () {
      if (this.customerDetails.type !== null) {
        const possibleSubscriptions = this.telephony.possibleSubscriptions[this.customerDetails.type]

        if (possibleSubscriptions.length === 1) {
          this.telephony.subscriptionChoice = possibleSubscriptions[0]
        }

        const urlParams = new URLSearchParams(window.location.search)
        const telephonySubscriptionChoice = urlParams.get('telephonySubscriptionChoice')

        if (this.telephony.subscriptionChoice === null) {
          const subscriptionChoiceObject = this.telephony.possibleSubscriptions[[this.customerDetails.type]].find(x => x.id === telephonySubscriptionChoice)

          if (subscriptionChoiceObject) {
            this.telephony.subscriptionChoice = subscriptionChoiceObject
          }
        }

        const broadbandNetChoice = urlParams.get('broadbandNetChoice')

        if (this.broadband.netChoice === null) {
          const netChoiceObject = this.broadband.possibleNets[[this.customerDetails.type]].find(x => x.value === broadbandNetChoice)

          if (netChoiceObject) {
            this.broadband.netChoice = netChoiceObject

            const broadbandSpeedChoice = urlParams.get('broadbandSpeedChoice')

            const speedChoiceObject = netChoiceObject.speeds.find(x => x.id === broadbandSpeedChoice)

            if (speedChoiceObject) {
              this.broadband.speedChoice = speedChoiceObject
            }
          }
        }
      }
    },
  },

  async created() {
    const products = (await axios.get('/api/products')).data

    this.broadband.possibleNets = products.BROADBAND
    this.telephony.possibleSubscriptions = products.TELEPHONY

    this.activationDate = this.inTwoDays()
    this.telephony.numberNew.prefixes = prefixes

    const urlParams = new URLSearchParams(window.location.search)
    const type = urlParams.get('type')

    if (type === 'individual' || type === 'company') {
      this.customerDetails.type = type.toUpperCase()
    }

    if (typeof urlParams.get('hideLogo') === 'string') {
      this.hideLogo = true
    }

    if (typeof urlParams.get('broadband') === 'string') {
      this.broadband.isActive = true
    }

    if (typeof urlParams.get('telephony') === 'string') {
      this.telephony.isActive = true
    }
  },

  methods: {
    saveActivationDate(newActivationDate) {
      this.$refs.dateDialog[0].save(newActivationDate)
    },

    transformIdentityNumber(v, t) {
      let tV = v.replace(/-/, '')

      if (!/^[0-9]+$/.test(tV)) {
        return v
      }

      if (tV.length === 10) {
        if (t === 'INDIVIDUAL') {
          const year = parseInt(tV.substring(0, 2))

          let thisYear = 100 - new Date().getYear()
          let addon

          if (year > thisYear) {
            addon = new Date().getFullYear() - 100
          } else {
            addon = new Date().getFullYear()
          }

          addon = addon.toString().substring(0, 2)

          return addon + tV
        }

        if (t === 'COMPANY') {
          return '16' + tV
        }
      }

      return tV
    },

    transformEmailAddress(v) {
      return v.toLocaleLowerCase()
    },

    transformPhoneNumber(v) {
      v = v.replace(/[ \\-]/g, '')

      if (v.substring(0, 2) === '00') {
        v = '+' + v.substring(2)
      }

      if (v.substring(0, 1) === '0') {
        v = '+46' + v.substring(1)
      }

      return v
    },

    transformZipCode(v) {
      return v.replace(/ /g, '')
    },

    inTwoDays() {
      const twoDays = new Date()
      twoDays.setDate(twoDays.getDate() + 2)
      return twoDays.toISOString().slice(0, 10)
    },

    async submitOrder() {
      this.isSendingOrder = true

      const order = {
        customer: {
          type: this.customerDetails.type,
          name: this.customerDetails.name,
          identityNumber: this.customerDetails.identityNumber,
          emailAddress: this.customerDetails.emailAddress,
          phoneNumber: this.customerDetails.phoneNumber,
          street: this.customerDetails.street,
          zipCode: this.customerDetails.zipCode,
          city: this.customerDetails.city,
          preferredInvoiceDelivery: this.customerDetails.preferredInvoiceDelivery,
          comment: this.customerDetails.comment || null,
        },
        activationDate: this.activationDate,
      }

      if (this.broadband.isActive) {
        order.broadband = {
          net: this.broadband.netChoice.value,
          speed: this.broadband.speedChoice.id,
        }
      }

      if (this.telephony.isActive) {
        order.telephony = {
          subscription: this.telephony.subscriptionChoice.id,
          number: {
            action: this.telephony.numberChoice,
          },
          extras: this.telephony.extraChoices,
        }

        if (this.telephony.numberChoice === 'KEEP') {
          order.telephony.number.number = this.telephony.numberKeep.number
          order.telephony.number.carrier = this.telephony.numberKeep.carrier
        } else if (this.telephony.numberChoice === 'NEW') {
          order.telephony.number.prefix = this.telephony.numberNew.prefix.value
        }
      }

      try {
        await axios.post('/api/orders', order)
        this.orderIsSent = true
      } catch {
        alert('Din beställning kunde inte skickas.')
      }

      this.isSendingOrder = false
    },

    toStep(step) {
      this.step = step
    },

    okUntil(step) {
      return (
        Array.from(
          {
            length: step,
          },
          (v, k) => k + 1
        ).filter(x => !this.isOk(x)).length === 0
      )
    },

    isOk(step) {
      switch (step) {
        case 1: {
          if (!this.broadband.isActive && !this.telephony.isActive) {
            return false
          }

          if (this.broadband.isActive) {
            if (this.broadband.netChoice === null || this.broadband.speedChoice === null) {
              return false
            }
          }

          if (this.telephony.isActive) {
            if (this.telephony.subscriptionChoice === null) {
              return false
            }

            if (this.telephony.numberChoice === null) {
              return false
            }

            if (this.telephony.numberChoice === 'KEEP') {
              if (!this.telephony.numberKeep.number || !this.telephony.numberKeep.carrier) {
                return false
              }
            }

            if (this.telephony.numberChoice === 'NEW') {
              if (!this.telephony.numberNew.prefix) {
                return false
              }
            }
          }

          return true
        }

        case 2: {
          const d = this.customerDetails

          if (!d.name || !d.identityNumber || !d.emailAddress || !d.phoneNumber || !d.street || !d.zipCode || !d.city) {
            return false
          }

          return true
        }

        default:
          return false
      }
    },
  },
}
</script>

<style>
.no-link-color a {
  text-decoration: none !important;
}

.container {
  max-width: 1000px;
}
</style>
